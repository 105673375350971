import React, { useContext, useState } from "react";
import {
  TrashIcon,
  FilmIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { generateUniqueId } from "../../../utils/utils";
import { DarkModeContext } from "../../../context/darkModeContext";
import MarkdownEditor from "../../MarkdownRender/MarkdownEditor/MarkdownEditor";

const AddHeadingTopicsInformation = ({
  chapterIndex,
  headingIndex,
  formData,
  setFormData,
  setVideoFiles,
}) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  const [dragActive, setDragActive] = useState(false);
  const [draggingOver, setDraggingOver] = useState(null);

  const handleInputChange = (e, topicIndex) => {
    if (e.target.files && e.target.files[0]) {
      const key = `${chapterIndex}_${headingIndex}_${topicIndex}`;

      setVideoFiles((prev) => ({
        ...prev,
        [key]: {
          chapterIndex,
          headingIndex,
          topicIndex,
          file: e.target.files[0],
        },
      }));
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const base64String = readerEvent?.target?.result;
        const updatedFormData = { ...formData };
        updatedFormData.grades[0].chapters[chapterIndex].headings[
          headingIndex
        ].topics[topicIndex].video_link = base64String;
        setFormData(updatedFormData);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function handleDrop(e, topicIndex) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const key = `${chapterIndex}_${headingIndex}_${topicIndex}`;
      setVideoFiles((prev) => ({
        ...prev,
        [key]: {
          chapterIndex,
          headingIndex,
          topicIndex,
          file: e.dataTransfer.files[0],
        },
      }));
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const base64String = readerEvent?.target?.result;
        const updatedFormData = { ...formData };
        updatedFormData.grades[0].chapters[chapterIndex].headings[
          headingIndex
        ].topics[topicIndex].video_link = base64String;
        setFormData(updatedFormData);
      };

      reader.readAsDataURL(e.dataTransfer.files[0]);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setDraggingOver(null);
  }

  function handleDragOver(e, topicIndex) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
    setDraggingOver({
      chapterIndex,
      headingIndex,
      topicIndex,
    });
  }

  function handleDragEnter(e, topicIndex) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
    setDraggingOver({
      chapterIndex,
      headingIndex,
      topicIndex,
    });
  }

  const addTopicHandler = () => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters[chapterIndex].headings[
      headingIndex
    ].topics.push({
      _id: generateUniqueId(),
      name: "",
    });
    setFormData(updatedFormData);
  };

  const deleteTopicHandler = (topicIndex) => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters[chapterIndex].headings[
      headingIndex
    ].topics.splice(topicIndex, 1);
    setFormData(updatedFormData);
  };

  return (
    <div className="py-1 px-3">
      {formData?.grades[0]?.chapters[chapterIndex]?.headings[
        headingIndex
      ]?.topics?.map((topic, topicIndex) => (
        <div key={topicIndex} className="py-2">
          <div className="flex items-center space-x-2 justify-between mb-1">
            <h5 className="courseHeadingStyle">Topic {topicIndex + 1} *</h5>
            {topicIndex > 0 && (
              <TrashIcon
                className="text-red-500 h-5 w-5 cursor-pointer"
                onClick={() => deleteTopicHandler(topicIndex)}
              />
            )}
          </div>
          <input
            className="addCourseNameInputStyle mb-4"
            type="text"
            placeholder="Topic Name"
            value={topic.name}
            onChange={(e) => {
              const updatedFormData = { ...formData };
              updatedFormData.grades[0].chapters[chapterIndex].headings[
                headingIndex
              ].topics[topicIndex].name = e.target.value;
              setFormData(updatedFormData);
            }}
          />

          <p className="font-urbanist font-semibold text-black dark:text-white text-sm block mb-2">
            Content *
          </p>
          <div className={`markdown-editor ${darkMode ? "dark-mode" : ""}`}>
            <MarkdownEditor
              topic={topic}
              formData={formData}
              setFormData={setFormData}
              chapterIndex={chapterIndex}
              headingIndex={headingIndex}
              topicIndex={topicIndex}
            />
          </div>

          <div
            className={`${
              dragActive &&
              draggingOver &&
              chapterIndex === draggingOver.chapterIndex &&
              headingIndex === draggingOver.headingIndex &&
              topicIndex === draggingOver.topicIndex
                ? "bg-blue-400"
                : "bg-gray47 dark:bg-purple14"
            }  px-4 mt-24 sm:mt-10 mb-4 py-4 rounded-lg dark:border dark:border-purple26`}
          >
            <p className="font-urbanist font-semibold text-black dark:text-white text-sm block mb-4">
              Upload Video
            </p>

            <input
              type="file"
              accept=".mp4, .mov"
              id={`input-file-upload-${chapterIndex}-${headingIndex}-${topicIndex}`}
              hidden
              onChange={(e) => handleInputChange(e, topicIndex)}
            />

            <label
              htmlFor={`input-file-upload-${chapterIndex}-${headingIndex}-${topicIndex}`}
            >
              <div
                className={`flex flex-col md:flex-row items-center space-x-2 px-2 md:px-4`}
              >
                <div className="w-full md:basis-1/2 bg-white dark:bg-purple14 dark:border dark:border-purple26 rounded-md flex flex-col items-center justify-center space-y-4 py-5 sm:py-10 md:py-20">
                  <FilmIcon className="h-8 w-8 text-gray30" />
                  <p className="text-xs text-center font-urbanist font-semibold text-black dark:text-white">
                    Upload your video
                  </p>
                </div>

                <div
                  className={`hidden w-full md:basis-1/2 rounded-md md:flex flex-col items-center justify-center`}
                  onDragEnter={(e) => handleDragEnter(e, topicIndex)}
                  onDrop={(e) => handleDrop(e, topicIndex)}
                  onDragLeave={handleDragLeave}
                  onDragOver={(e) => handleDragOver(e, topicIndex)}
                >
                  <div className="flex items-center justify-center bg-gray23 h-20 w-20 rounded-full">
                    <ArrowUpTrayIcon className="h-10 w-10 text-purple6" />
                  </div>
                  <p className="text-sm text-center font-urbanist font-semibold text-black dark:text-white">
                    Drag and drop video files to upload Your videos will be
                    private until you publish them.
                  </p>
                </div>

                <div className="cursor-pointer flex items-center space-x-4 justify-center md:hidden w-full text-center px-4 py-2 font-urbanist font-semibold text-purple6 bg-gray23 my-2 sm:my-4">
                  <ArrowUpTrayIcon className="h-4 w-4 text-purple6" />
                  <p className="">Upload Video</p>
                </div>
              </div>
            </label>
          </div>

          {topic?.video_link && (
            <ReactPlayer
              url={topic?.video_link}
              width="100%"
              height="300px"
              controls
              style={{
                marginBottom: "16px",
              }}
            />
          )}
        </div>
      ))}
      <button className="courseAddButtonStyle" onClick={addTopicHandler}>
        Add Topic
      </button>
    </div>
  );
};

export default AddHeadingTopicsInformation;
