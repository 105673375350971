import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  BookmarkIcon,
  BookmarkSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { addSuffixToNumber } from "../../utils/utils";
import CourseLevelPill from "./CourseLevelPill/CourseLevelPill";
import { UserContext } from "../../context/user";
import { handleBookmarkCourse } from "../../utils/network-requests";
import ShowUserImage from "../ShowUserImage/ShowUserImage";

const CourseCard = ({ data, courseBookmarked = false }) => {
  const {
    state: { userInfo, bookmarkedCourses },
    updateUserBookmarkedCourses,
  } = useContext(UserContext);
  const [bookmarkRunning, setBookmarkRunning] = useState(false);

  const handleAddOrRemoveCourseBookmark = async (activity) => {
    setBookmarkRunning(true);
    try {
      await handleBookmarkCourse({
        activity,
        courseId: data._id,
        authToken: userInfo?.authToken,
      });

      const currentBookmarkedCourses = bookmarkedCourses || [];
      let updatedBookmarkedCourse = [];
      if (activity === "remove_course_from_bookmark") {
        updatedBookmarkedCourse = currentBookmarkedCourses?.filter(
          (id) => id?.toString() !== data?._id?.toString()
        );
      } else {
        updatedBookmarkedCourse = [
          ...currentBookmarkedCourses,
          data?._id?.toString(),
        ];
      }
      updateUserBookmarkedCourses(updatedBookmarkedCourse);
      setBookmarkRunning(false);
    } catch (error) {
      setBookmarkRunning(false);
    }
  };

  return (
    <div className="mb-4 hover:shadow-md dark:bg-purple2 bg-white border dark:border-purple31 dark:shadow-shadow12 rounded-[10px] pt-2 pb-2">
      <Link to={`/course-details/${data?.urlSlug}`} className="block pb-2">
        <div className="w-full h-full px-2 relative flex-1">
          <img
            src={data?.imageUrl}
            alt="course_thumbnail"
            className="w-full h-36 object-cover rounded-md"
          />

          <div
            className={`absolute top-2 left-4 px-3 py-1 font-urbanist text-white font-medium text-xs sm:text-base ${
              data?.cost?.toLowerCase() === "free" ||
              parseFloat(data?.cost || 0) === undefined ||
              parseFloat(data?.cost || 0) <= 0 ||
              parseFloat(data?.cost || 0) - (data?.discount || 0) <= 0
                ? "bg-background8 "
                : "bg-purple1"
            } rounded-2xl `}
          >
            {data?.cost?.toLowerCase() === "free" ||
            parseFloat(data?.cost || 0) === undefined ||
            parseFloat(data?.cost || 0) <= 0 ||
            parseFloat(data?.cost || 0) - (data?.discount || 0) <= 0
              ? "Free"
              : "$" + (parseFloat(data?.cost || 0) - (data?.discount || 0))}
          </div>
        </div>
        {/* Card body  */}
        <div className="px-2 mt-2 h-28">
          <div className="h-12">
            <h3 className="text-sm sm:text-base dark:text-white text-black/80 font-urbanist font-semibold mb-1 line-clamp-2">
              {data?.name}
            </h3>
          </div>
          {data?.grades[0]?.name && (
            <div className="h-5">
              <span className="text-sm sm:text-base dark:text-white text-black/80 font-urbanist font-semibold mb-0.5 line-clamp-1">
                {addSuffixToNumber(data?.grades[0]?.name)} - Grade
              </span>
            </div>
          )}
          {data?.course_level && (
            <CourseLevelPill courseLevel={data?.course_level} />
          )}
        </div>
      </Link>
      {/* Card Footer */}
      <div className="px-2 pt-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <ShowUserImage
            userImage={data?.avatar}
            className="h-7 w-7"
            iconStyle={"h-7 w-7 dark:text-white text-gray20"}
          />
          <span className="font-urbanist font-medium text-sm dark:text-white text-blue25">
            {data?.author}
          </span>
        </div>
        {courseBookmarked ? (
          <button
            disabled={bookmarkRunning}
            onClick={() =>
              handleAddOrRemoveCourseBookmark("remove_course_from_bookmark")
            }
          >
            <BookmarkSquareIcon className="h-6 w-6 text-blue-600 dark:fill-blue-600" />
          </button>
        ) : (
          <button
            disabled={bookmarkRunning}
            onClick={() =>
              handleAddOrRemoveCourseBookmark("add_course_to_bookmark")
            }
          >
            <BookmarkIcon className="h-6 w-6 dark:text-white text-gray20" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CourseCard;
