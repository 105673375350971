import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/user";
import axios from "axios";
import { getURLs } from "../../../urlConfig";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import AddCourseInformation from "../../CreateCourseComponents/AddCourseInformation/AddCourseInformation";
import { CourseFields } from "../../../utils/staticData";
import { AppDataContext } from "../../../context/appData";
import { fetchPageData } from "../../../utils/network-requests";
import ChooseCategoryCard from "../../CreateCourseComponents/CourseChooseCategory/ChooseCategoryCard/ChooseCategoryCard";

const AdminAddDetailsCSVCourse = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [formData, setFormData] = useState({
    ...CourseFields,
  });
  const [books, setBooks] = useState([]);
  const [showBooks, setShowBooks] = useState(false);
  const [selectedBook, setSelectedBook] = useState({});
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });

  const handleScroll = (e) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 1 &&
      page < finalPage
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleBookSelection = (book) => {
    setSelectedBook(book);
    setShowBooks(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (formData?.urlSlug?.trim()) {
      setError({ show: false, message: "" });
      axios
        .post(
          getURLs("set-book-details"),
          {
            bookInfo: {
              ...formData,
              course_category: formData?.category,
              _id: selectedBook._id,
              name: selectedBook.name,
            },
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then(() => {
          setBooks((prev) =>
            prev.filter((book) => book._id !== selectedBook._id)
          );
          setSelectedBook({});
          setShowBooks(false);
          setFormData({ ...CourseFields });
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          )
            setError({
              show: true,
              message: err?.response?.data?.message || "Something went wrong",
            });
        });
    }
  };

  const fetchBooks = async () => {
    if (page === finalPage && firstResponseCame) return;
    setLoading(true);
    axios
      .get(getURLs("all-books-name"), {
        params: {
          page,
          // search: searchTerm,
        },
        withCredentials: true,
        headers: {
          "auth-token": userInfo?.authToken,
        },
      })
      .then((response) => {
        const newBooks = response.data.books;
        setBooks((prevBooks) => [...prevBooks, ...newBooks]);
        if (response.data?.next) {
          setFinalPage(response.data?.next + 1);
        } else {
          setFinalPage(page);
        }
        setFirstResponseCame(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching books:", err);
        setFirstResponseCame(true);
        setLoading(false);
      });
  };

  // useeffect to fetch all the books
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      fetchBooks();
    }
  }, [userInfo, page]);

  // fetch options for creating course
  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.instructorCreateCoursePage]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 p-6">
      <h1 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        All Courses without Details
      </h1>

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 min-h-[500px] px-4 py-2">
        {books?.length > 0 ? (
          <div className="w-full max-w-xl">
            {/* show books */}
            <div className="flex md:flex-row flex-col md:items-center space-y-1 md:space-x-4 px-4 py-2">
              <p className="font-urbanist font-medium text-black1 dark:text-white whitespace-nowrap">
                Select a Course
              </p>
              <div
                className="flex items-center px-4 py-1 justify-between w-full cursor-pointer relative border dark:border-purple15 rounded-md"
                onClick={() => setShowBooks((prev) => !prev)}
              >
                <p className="font-urbanist text-black1 dark:text-white text-sm sm:text-base h-6">
                  {Object.keys(selectedBook).length > 0
                    ? selectedBook.name
                    : ""}
                </p>
                <ChevronDownIcon className="h-4 w-4 text-gray-500 dark:text-white" />
                {showBooks && (
                  <div
                    className="absolute top-8 left-0 right-0 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-slate-300 z-10 bg-white dark:bg-purple14 shadow-md border dark:border-purple15 rounded-md flex flex-col space-y-1"
                    onScroll={handleScroll}
                  >
                    {books.map((book) => (
                      <div
                        key={book._id}
                        className="flex flex-col space-y-0.5 border-b dark:border-b-purple15 py-2 px-4 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleBookSelection(book);
                        }}
                      >
                        <p className="font-urbanist font-normal text-gray-500 dark:text-white">
                          ID: {book._id}
                        </p>
                        <p className="font-urbanist font-normal text-gray-500 dark:text-white">
                          Name: {book.name}
                        </p>
                      </div>
                    ))}
                    {loading && (
                      <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                        Loading...
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* show form once book is selected */}
            {Object.keys(selectedBook).length > 0 && (
              <div className="flex flex-col space-y-4 mt-4">
                <div className="flex flex-col space-y-1 px-4">
                  <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
                    Course name
                  </p>
                  <input
                    className="dark:bg-purple14 dark:border-purple26 disabled:bg-gray-100 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray-500 placeholder:dark:text-gray-200 dark:text-gray-500"
                    value={selectedBook.name}
                    disabled
                  />
                </div>
                <div className="flex flex-col space-y-1 px-4">
                  <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
                    Course Category
                  </p>
                  <div className="flex flex-wrap items-center gap-4 mb-6">
                    {appData?.instructorCreateCoursePage?.selectCategorySectionData?.availableCategories?.map(
                      (data) => (
                        <ChooseCategoryCard
                          category={data.name}
                          key={data.id}
                          selectedCategory={formData?.category}
                          handleClick={handleInputChange}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="flex flex-col space-y-1 px-4">
                  <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
                    Course url title
                  </p>
                  <input
                    className="dark:bg-purple14 dark:border-purple26 disabled:bg-gray-100 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray-500 placeholder:dark:text-gray-200 dark:text-gray-500"
                    value={formData?.urlSlug || ""}
                    onChange={handleInputChange}
                    name="urlSlug"
                  />
                </div>
                <AddCourseInformation
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleInputChange}
                  handleSave={handleSave}
                  availableGradesData={
                    appData?.instructorCreateCoursePage?.addCourseFormData
                      ?.availableGradesData
                  }
                  showGradeInput={false}
                />
                {error?.show && (
                  <p className="my-4 block text-sm font-urbanist font-medium text-red-500">
                    {error?.message}
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
            No Books available
          </p>
        )}
      </div>
    </div>
  );
};

export default AdminAddDetailsCSVCourse;
