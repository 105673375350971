import React, { useState } from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import MarkdownRenderer from "../MarkdownRenderer";

const MarkdownEditor = ({
  topic,
  formData,
  setFormData,
  chapterIndex,
  headingIndex,
  topicIndex,
}) => {
  const [value, setValue] = useState(topic.content || "");
  const [selectedTab, setSelectedTab] = useState("write");

  const handleTopicContentUpdate = (topicContent) => {
    const updatedFormData = { ...formData };
    updatedFormData.grades[0].chapters[chapterIndex].headings[
      headingIndex
    ].topics[topicIndex].content = topicContent;
    setFormData(updatedFormData);
    setValue(topicContent);
  };

  return (
    <div className="markdown-editor h-[400px] overflow-auto">
      <ReactMde
        value={value}
        onChange={(value) => handleTopicContentUpdate(value)}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(
            <MarkdownRenderer markdown={markdown} hideCopyButton={true} />
          )
        }
        classes={{
          textArea: "!h-[340px] outline-none",
        }}
      />
    </div>
  );
};

export default MarkdownEditor;
