import { UserProvider } from "./user";
import { AppDataProvider } from "./appData";
import { InstructorDataProvider } from "./instructorData";
import { DarkModeProvider } from "./darkModeContext";
import { PromptRunningProvider } from "./promptContext";

const providers = [
  UserProvider,
  AppDataProvider,
  InstructorDataProvider,
  DarkModeProvider,
  PromptRunningProvider,
];

export default providers;
