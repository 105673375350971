import { PROMPT_RUNNING, STOP_PROMPT } from "./keys";

export const updatePromptRunning = (dispatch) => (payload) => {
  dispatch({
    type: PROMPT_RUNNING,
    payload: { ...payload },
  });
};

export const updateStopPrompt = (dispatch) => (payload) => {
  dispatch({
    type: STOP_PROMPT,
    payload: { ...payload },
  });
};
