import React from "react";
import SectionQuiz from "../../SectionQuiz/SectionQuiz";
import ShowContentDescription from "../../ShowContentDescription/ShowContentDescription";

const OverviewDetails = ({
  courseData,
  containerRef,
  textStyle,
  sectionContent,
  isExpandable,
  expanded,
  toggleExpand,
}) => {
  return (
    <div>
      {/* dvivider */}
      <div className="w-full border-b border-b-gray-300 my-3" />

      <h3 className="font-urbanist font-semibold text-base dark:text-white text-black block mb-5">
        Course
      </h3>
      <div className="flex flex-col md:flex-row space-x-0 space-y-4 items-start md:space-y-0 md:space-x-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <h5 className="font-urbanist text-sm font-bold text-black dark:text-white">
              Skill level
            </h5>
            <div
              className={`${
                courseData?.level?.toLowerCase() === "advance"
                  ? "bg-blue41 text-blue27"
                  : courseData?.level?.toLowerCase() === "medium"
                  ? "bg-yellow2 text-yellow3"
                  : courseData?.level?.toLowerCase() === "beginner"
                  ? "bg-green2 text-green3"
                  : ""
              } px-4 py-1 rounded-[30px] font-urbanist font-medium text-xs uppercase`}
            >
              {courseData?.level}
            </div>
          </div>
          <p className="font-urbanist font-medium text-gray40 text-sm">
            {courseData?.studentsEnrolled || 0} students enrolled
          </p>
        </div>

        <div className="flex flex-col space-y-2">
          <h5 className="font-urbanist text-sm font-bold text-black dark:text-white">
            Certificate
          </h5>
          <div className="px-4 py-1 rounded-[30px] font-urbanist font-medium text-gray39 bg-gray41">
            Preview Certificate
          </div>
        </div>
      </div>
      {/* divider */}
      <div className="w-full border-b border-b-gray-300 my-3" />

      <div className="lg:hidden block">
        <ShowContentDescription
          expanded={expanded}
          containerRef={containerRef}
          content={sectionContent?.content}
          isExpandable={isExpandable}
          textStyle={textStyle}
          toggleExpand={toggleExpand}
        />
      </div>
      {/* quiz */}
      <div className="max-h-[500px] overflow-y-auto scrollbar-track-slate-50 px-4 py-2 border dark:md:border-purple13 rounded-md my-4">
        <h3 className="font-urbanist font-semibold text-base dark:text-white text-black block mb-5">
          Quiz
        </h3>
        <div className="">
          <SectionQuiz quizContent={sectionContent?.quiz} />
        </div>
      </div>
    </div>
  );
};

export default OverviewDetails;
