import React from "react";

const OverviewCard = ({
  Icon,
  iconBg,
  title,
  value,
  cardBg,
  borderBottom,
  handleCardClick = null,
}) => {
  return (
    <div
      className={`rounded-xl px-4 py-5 ${
        cardBg ? cardBg : "bg-orange-400/70"
      } flex flex-col space-y-5 w-full cursor-pointer`}
      onClick={handleCardClick ? handleCardClick : null}
    >
      <div className="flex items-center space-x-2 lg:flex-col lg:items-start lg:space-y-1 lg:space-x-0 xl:flex-row xl:items-center xl:space-y-0 xl:space-x-2">
        <div
          className={`h-8 w-8 ${
            iconBg ? iconBg : "bg-orange-400"
          } rounded-md flex items-center justify-center`}
        >
          <Icon className="h-5 w-5 text-white" />
        </div>
        <p className="text-black1 dark:text-white font-urbanist font-medium text-sm">
          {title}
        </p>
      </div>

      <div className="space-y-0.5">
        <p className="font-urbanist font-semibold text-lg md:text-2xl text-black1 dark:text-white">
          {value}
        </p>
        <div
          className={`border-b-2 w-10 ${
            borderBottom ? borderBottom : "border-t-orange-400"
          }`}
        />
      </div>
    </div>
  );
};

export default OverviewCard;
