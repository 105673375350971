import React, { useContext, useState } from "react";
import { CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import PaypalLogo from "../../../../images/paypal.svg";
import StripeLogo from "../../../../images/stripe.svg";
import axios from "axios";
import { getURLs } from "../../../../urlConfig";
import { UserContext } from "../../../../context/user";
import CustomModal from "../../../CustomModal/CustomModal";
import ConfirmationModal from "../../../../modal/ConfirmationModal";

const ActivePlan = ({ planData, activePlanDetails }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [cancelButtonDisable, setCancelButtonDisable] = useState(false);
  const [planCancelled, setPlanCancelled] = useState(
    activePlanDetails?.planCancelled
  );
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [cancelSubscriptionError, setCancelSubscriptionError] = useState({
    show: false,
    message: "",
  });
  const [
    showCancelSubscriptionSuccessModal,
    setShowCancelSubscriptionSuccessModal,
  ] = useState(false);

  const handleCancelPlan = () => {
    try {
      if (cancelButtonDisable || planCancelled) return;
      setCancelButtonDisable(true);
      axios
        .put(
          getURLs("cancel-subscription"),
          {},
          {
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then(async () => {
          handleCancelModalClose();
          setShowCancelSubscriptionSuccessModal(true);
          setCancelSubscriptionError({ show: false, message: "" });
          setCancelButtonDisable(false);
          setPlanCancelled(true);
        })
        .catch((err) => {
          console.log(err);
          setCancelSubscriptionError({
            show: true,
            message: "An Error Occurred, please try later",
          });
          setCancelButtonDisable(false);
        });
    } catch (error) {
      console.log(error);
      setCancelButtonDisable(false);
      setCancelSubscriptionError({
        show: true,
        message: "An Error Occurred, please try later",
      });
    }
  };

  const handleCancelModalClose = () => {
    setShowCancelConfirmModal(false);
    setCancelButtonDisable(false);
    setCancelSubscriptionError({ show: false, message: "" });
  };

  return (
    <div
      className={`relative border dark:border-purple3 dark:bg-purple16 bg-white grid grid-cols-1 xl:grid-cols-3 gap-5 px-5 py-6 rounded-2xl shadow-lg w-max`}
    >
      {/* left */}
      <div className="">
        <div className="flex items-center space-x-2 mb-5">
          <button
            className="rounded-lg border-none text-white text-sm md:text-base font-urbanist font-bold w-max px-10 py-2"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
          >
            Active Plan
          </button>
          {activePlanDetails?.activePlanName?.toLowerCase() !== "basic" && (
            <button
              className="rounded-lg border-none bg-red-500 disabled:bg-red-100 text-white text-sm md:text-base font-urbanist font-bold w-max px-10 py-2"
              disabled={planCancelled}
              onClick={() => setShowCancelConfirmModal(true)}
            >
              Cancel Plan
            </button>
          )}
        </div>
        {cancelSubscriptionError.show && (
          <p className="font-urbanist font-medium text-sm text-red-500 mb-2">
            {cancelSubscriptionError.message}
          </p>
        )}
        <div className="flex flex-col space-y-3">
          <h1
            className={`font-bold font-inter text-xl dark:text-white text-gray36 md:text-2xl`}
          >
            {activePlanDetails?.activePlanName}
          </h1>

          <p
            className={`block font-urbanist font-semibold text-sm text-purple3 mb-3`}
          >
            <span className="font-urbanist font-bold text-2xl md:text-4xl">
              {activePlanDetails?.subAmount === 0
                ? "FREE"
                : parseInt(activePlanDetails?.subAmount) === 0
                ? "FREE"
                : `$${activePlanDetails?.subAmount}`}
            </span>
            {activePlanDetails?.subAmount !== 0 &&
              activePlanDetails?.subscriptionEndDate &&
              `valid upto ${new Date(
                activePlanDetails?.subscriptionEndDate
              )?.toDateString()}`}
          </p>

          <p className={`font-urbanist font-normal text-sm text-gray28`}>
            {planData?.planDescription}
          </p>
        </div>
      </div>

      {/* center */}
      <div className="flex flex-col mt-5 space-y-4 mb-8 border-l-0 border-t border-t-gray-200 xl:border-t-0 xl:border-l xl:border-l-gray-200 pt-5 xl:px-5 h-full">
        <p className="font-urbanist font-semibold text-lg text-black dark:text-white">
          Benefits
        </p>

        {planData?.featuresAvailable?.map((feature, index) => (
          <div key={index} className={`flex items-center space-x-2`}>
            <div
              className={`flex items-center justify-center h-5 w-5 rounded-full bg-gray38 dark:bg-purple13`}
            >
              <CheckIcon className="text-blue-500 h-3" />
            </div>
            <p
              className={`font-inter font-medium text-xs dark:text-white text-blue21 `}
            >
              {feature.featureDescription}
            </p>
          </div>
        ))}
      </div>

      {/* right */}
      {planData?.planName?.toLowerCase() !== "basic" && (
        <div className="flex flex-col mt-5 space-y-4 mb-8 border-l-0 border-t border-t-gray-200 xl:border-t-0 xl:border-l xl:border-l-gray-200 pt-5 xl:px-5 h-full">
          <p className="font-urbanist font-semibold text-lg text-black dark:text-white">
            Membership & Billing
          </p>

          <div className="flex items-center space-x-2 font-urbanist font-semibold text-gray36 dark:">
            <p>Start Date: </p>
            <p>
              {activePlanDetails?.subscriptionStartDate &&
                new Date(
                  activePlanDetails?.subscriptionStartDate
                ).toDateString()}
            </p>
          </div>

          <div className="flex items-center space-x-2 font-urbanist font-semibold text-gray36 dark:">
            <p>End Date: </p>
            <p>
              {activePlanDetails?.subscriptionEndDate &&
                new Date(activePlanDetails?.subscriptionEndDate).toDateString()}
            </p>
          </div>

          <div className="">
            {activePlanDetails?.paymentVendor === "paypal" ? (
              <img
                src={PaypalLogo}
                alt="paypal"
                className="object-contain h-8 w-16"
              />
            ) : activePlanDetails?.paymentVendor === "stripe" ? (
              <img
                src={StripeLogo}
                alt="paypal"
                className="object-contain h-8 w-16"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <ConfirmationModal
        confirmationMessage={
          "Are you sure you want to cancel your subscription ?"
        }
        disableButtons={cancelButtonDisable}
        error={cancelSubscriptionError}
        handleConfirmClick={handleCancelPlan}
        handleModalClose={handleCancelModalClose}
        show={showCancelConfirmModal}
      />

      <CustomModal show={showCancelSubscriptionSuccessModal} centered>
        {showCancelSubscriptionSuccessModal && (
          <div className="relative flex flex-col space-y-1 py-4 px-6">
            <div className="absolute right-4">
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={() => setShowCancelSubscriptionSuccessModal(false)}
              />
            </div>
            <h4 className="text-sm font-inter font-medium flex-1 text-start text-black pr-8">
              You subscription has been successfully cancelled. It will stop
              working after period expiration of your subscription
            </h4>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default ActivePlan;
