import React from "react";
import LevelIcon from "../../LevelIcon";

const CourseLevelPill = ({ courseLevel }) => {
  return (
    <div
      className={`flex items-center space-x-2 font-inter font-normal text-gray20 rounded-[28px] w-max px-2 py-1 mt-2 ${
        courseLevel?.toLowerCase() === "advance"
          ? "bg-purple30"
          : courseLevel?.toLowerCase() === "beginner"
          ? "bg-yellow2"
          : "bg-green2"
      }`}
    >
      <div className="flex items-center space-x-1">
        <LevelIcon level={courseLevel?.toLowerCase()} />
        <span
          className={`font-urbanist font-normal text-sm ${
            courseLevel?.toLowerCase() === "advance"
              ? "text-purple6"
              : courseLevel?.toLowerCase() === "beginner"
              ? "text-yellow3"
              : "text-green3"
          }`}
        >
          {courseLevel}
        </span>
      </div>
    </div>
  );
};

export default CourseLevelPill;
