import React, { useEffect, useState } from "react";
import CustomRadioButton from "../../CustomRadioButton";
import MarkdownRenderer from "../../MarkdownRender/MarkdownRenderer";

const SectionQuiz = ({ quizContent }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);

  const handleOptionSelect = (questionIndex, option) => {
    setUserAnswers({
      ...userAnswers,
      [questionIndex]: option,
    });
  };

  const handleSubmit = () => {
    setShowResults(true);
  };

  useEffect(() => {
    setUserAnswers({});
    setShowResults(false);
  }, [quizContent]);

  return (
    <div className="flex flex-col space-y-6">
      {quizContent?.map((content, index) => (
        <div
          key={index}
          className="flex flex-col space-y-2 text-sm font-urbanist font-medium dark:text-white"
        >
          <span className="inline-flex space-x-1 font-urbanist font-semibold text-sm text-black1">
            <p className="">Q{index + 1}: </p>
            <MarkdownRenderer
              customClassNames={"quizContainer"}
              markdown={content?.question || ""}
              hideCopyButton={true}
            />
          </span>
          <div className="md:border dark:md:border-none rounded-md">
            {content?.options?.map((option, optionIndex) => (
              <div
                key={optionIndex}
                className={`flex items-center space-x-4 px-4 py-4 rounded-md ${
                  userAnswers[index] === option
                    ? "bg-gray21 dark:bg-purple16"
                    : "bg-white dark:bg-purple14"
                } hover:bg-gray21 dark:hover:bg-purple16 border-b dark:border-b-purple15 cursor-pointer ${
                  index === 0
                    ? "hover:rounded-tl hover:rounded-tr rounded-t-md"
                    : index + 1 === content?.options?.length
                    ? "hover:rounded-b-md"
                    : ""
                }`}
                onClick={() => handleOptionSelect(index, option)}
              >
                <CustomRadioButton
                  selected={userAnswers[index] === option}
                  value={index}
                  handleChange={(value) => handleOptionSelect(index, option)}
                />

                <MarkdownRenderer
                  markdown={option}
                  hideCopyButton={true}
                  customClassNames="quizOptionContainer"
                />
              </div>
            ))}
          </div>
          {showResults && (
            <div
              className={`font-urbanist font-medium text-sm ${
                userAnswers[index] &&
                userAnswers[index] === quizContent?.[index]?.answer
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {userAnswers[index] &&
              userAnswers[index] === quizContent?.[index]?.answer
                ? "Correct!"
                : quizContent?.[index]?.answer
                ? `Incorrect, the correct answer is: ${quizContent?.[index]?.answer}`
                : "Correct Answer not provided for the question"}
            </div>
          )}
        </div>
      ))}
      {!showResults && (
        <div className="flex items-center justify-end mb-2">
          <button
            className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={handleSubmit}
          >
            Submit Quiz
          </button>
        </div>
      )}
    </div>
  );
};

export default SectionQuiz;
