import React from "react";
import ShowUserImage from "../../ShowUserImage/ShowUserImage";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import UserCalendar from "./UserCalendar/UserCalendar";

const UserDashboardRightPanel = ({ userInfo }) => {
  return (
    <div className="px-4 py-5 w-full">
      <div className="flex items-center space-x-2 justify-between mb-8">
        <h4 className="font-urbanist font-semibold text-lg text-black2 dark:text-white">
          Profile
        </h4>

        <Link to={"/dashboard/profile"}>
          <div className="w-8 h-8 flex items-center justify-center rounded-lg border border-gray-400">
            <PencilIcon className="h-4 w-4 text-gray-400" />
          </div>
        </Link>
      </div>
      <div className="flex flex-col items-center space-y-4 mb-8">
        <div className="h-24 w-24 rounded-full border-2 border-blue-400 flex flex-col items-center justify-center">
          <ShowUserImage
            userImage={userInfo?.profileImage}
            imgStyle="!h-20 !w-20"
            avatarStyle="!h-20 !w-20"
          />
        </div>

        <div className="space-y-1">
          <p className="font-semibold text-center font-urbanist text-sm dark:text-white text-black1">
            {userInfo?.name}
          </p>
          <p className="text-center text-xs text-gray13 dark:text-gray5 font-urbanist font-normal">
            Student Profile
          </p>
        </div>
      </div>

      <div>
        <UserCalendar />
      </div>
    </div>
  );
};

export default UserDashboardRightPanel;
