import {
  ADD_USER,
  UPDATE_SEARCHES_LEFT_FOR_USER,
  UPDATE_USER_BOT_INFO,
  UPDATE_USER_BOT_ID_INFO,
  UPDATE_USER_CHAT_ID_INFO,
  LOGOUT_USER,
  UPDATE_BOOKMARKED_COURSES,
  UPDATE_ENROLLED_COURSES,
  UPDATING_USER,
} from "./keys";

export const initialState = {
  userInfo: {},
  searchesLeftForUser: null,
  userBotInfo: {},
  bookmarkedCourses: null,
  enrolledCourses: null,
  userUpdated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_USER:
      return { ...state, userInfo: action.payload };

    case UPDATE_SEARCHES_LEFT_FOR_USER: {
      return {
        ...state,
        searchesLeftForUser: action.payload,
      };
    }

    case UPDATE_USER_BOT_INFO: {
      const { botIds, botChatIds } = action.payload;
      return {
        ...state,
        userBotInfo: { botIds, botChatIds },
      };
    }

    case UPDATE_USER_BOT_ID_INFO: {
      const newBotsInfo = {
        ...state.userBotInfo,
        botIds: { ...state.userBotInfo?.botIds, ...action.payload },
      };
      return {
        ...state,
        userBotInfo: { ...newBotsInfo },
      };
    }

    case UPDATE_USER_CHAT_ID_INFO: {
      const newChatIdsInfo = {
        ...state.userBotInfo,
        botChatIds: { ...state.userBotInfo?.botChatIds, ...action.payload },
      };
      return {
        ...state,
        userBotInfo: { ...newChatIdsInfo },
      };
    }

    case LOGOUT_USER:
      return { ...state, userInfo: {}, searchesLeftForUser: null };

    case UPDATE_BOOKMARKED_COURSES: {
      return {
        ...state,
        bookmarkedCourses: action.payload,
      };
    }

    case UPDATE_ENROLLED_COURSES: {
      return {
        ...state,
        enrolledCourses: action.payload,
      };
    }

    case UPDATING_USER:
      return { ...state, userUpdated: action.payload.updateStatus };

    default:
      return state;
  }
};

export default reducer;
