import React, { useContext, useEffect, useState } from "react";
import { PromptRunningContext } from "../../../context/promptContext";
import MarkdownRenderer from "../../MarkdownRender/MarkdownRenderer";

const ShowAnimatedText = ({ text }) => {
  const {
    state: { stopPrompt },
    updatePromptRunning,
  } = useContext(PromptRunningContext);
  const [displayResponse, setDisplayResponse] = useState("");
  const [currentMarkdownContent, setCurrentMarkdownContent] = useState(text);

  useEffect(() => {
    let intervalId = null;
    if (currentMarkdownContent?.toLowerCase() !== "generation stopped") {
      let i = 0;
      const stringResponse = currentMarkdownContent;
      intervalId = setInterval(() => {
        setDisplayResponse(stringResponse?.slice(0, i));
        i++;
        if (i > stringResponse.length) {
          clearInterval(intervalId);
          updatePromptRunning({ promptState: false });
        }
      }, 20);

      return () => clearInterval(intervalId);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [currentMarkdownContent]);

  useEffect(() => {
    if (stopPrompt) {
      setCurrentMarkdownContent("Generation Stopped");
    }
  }, [stopPrompt]);

  return <MarkdownRenderer markdown={displayResponse} />;
};

export default ShowAnimatedText;
