import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { UserContext } from "../../context/user";
import { AppDataContext } from "../../context/appData";
import { getURLs } from "../../urlConfig";
import DashboardHeader from "../DashboardHeader";
import { UserDashboardTabsData } from "../../utils/staticData";
import UserDashboardSidebar from "../DashboardSidebar/UserDashboardSidebar/UserDashboardSidebar";
import Loader from "../Loader";

const UserDashboardLayout = () => {
  // navigate state
  const navigate = useNavigate();

  // user info context
  const {
    state: { userInfo, searchesLeftForUser, enrolledCourses, userUpdated },
    updateSearchesLeftForUser,
    updateUserEnrolledCourses,
  } = useContext(UserContext);

  // app data context
  const {
    state: { appData, allCoursesData },
    initializeAllCoursesData,
    // initializeAllQuizesData,
  } = useContext(AppDataContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userUpdated && !Object.keys(userInfo || {}).length) {
      navigate("/login", {
        replace: true,
      });
      setLoading(false);
    }
  }, [userInfo, userUpdated]);

  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0 && !searchesLeftForUser) {
      axios
        .get(getURLs("searches-left"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          updateSearchesLeftForUser(res?.data?.freeSearchesLeft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0) {
      const promises = [];
      if (!allCoursesData) {
        const allBooksPromise = axios
          .get(getURLs("all-books"), {
            headers: {
              "auth-token": userInfo?.authToken,
            },
          })
          .then((res) => {
            initializeAllCoursesData(res.data?.AllCoursesData || []);
          })
          .catch((err) => {
            console.log(err);
          });

        promises.push(allBooksPromise);
      }

      // if (!allQuizesData) {
      //   const allQuizesPromise = axios
      //     .get(getURLs("get-all-quizes"), {
      //       headers: {
      //         "auth-token": userInfo?.authToken,
      //       },
      //     })
      //     .then((res) => {
      //       initializeAllQuizesData(res.data?.AllQuizesData);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      //     promises.push(allQuizesPromise)
      // }

      if (!enrolledCourses) {
        const enrolledCoursesPromise = axios
          .get(getURLs("user-enrolled-courses"), {
            headers: {
              "auth-token": userInfo?.authToken,
            },
          })
          .then((res) => {
            updateUserEnrolledCourses(res.data?.enrolledCourses);
          })
          .catch((err) => {
            console.log(err);
          });
        promises.push(enrolledCoursesPromise);
      }

      Promise.all(promises).finally(() => setLoading(false));
    }
  }, [userInfo, allCoursesData, enrolledCourses]);

  return (
    <>
      <Helmet>
        <title>Brainjee - Dashboard</title>
        <link rel="canonical" href="https://www.brainjee.com/dashboard" />
      </Helmet>

      <div className="flex flex-col bg-white dark:bg-darkBgColor1">
        <div className="contents lg:hidden w-full">
          <DashboardHeader
            pageData={appData?.homePageData}
            userInfo={userInfo}
            TabsData={UserDashboardTabsData}
            useRoutingSidebar
          />
        </div>
        <div className="flex h-full">
          <div className="hidden lg:basis-1/5 w-full h-full lg:inline-flex">
            <UserDashboardSidebar TabsData={UserDashboardTabsData} />
          </div>
          <div className="lg:basis-4/5 rounded-md w-full lg:border-l-2 lg:border-l-gray-200 lg:dark:border-l-purple16">
            <Outlet />
          </div>
        </div>
      </div>

      {loading && (
        <div className="absolute top-0 bottom-0 right-0 left-0 z-50 bg-black/10 flex items-center justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default UserDashboardLayout;
