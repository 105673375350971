import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../../../context/user";
import useDebounce from "../../../hooks/useDebounce";
import { getURLs } from "../../../urlConfig";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CourseCard from "../../CourseCard";
import { Link } from "react-router-dom";

const ShowEnrolledCourses = ({ useInDashboard = false }) => {
  const {
    state: { userInfo, enrolledCourses, bookmarkedCourses },
    updateUserEnrolledCourses,
  } = useContext(UserContext);

  const [coursesData, setCoursesData] = useState(enrolledCourses);
  // loading state
  const [loading, setLoading] = useState(false);
  // search state
  const [searchInput, setSearchInput] = useState("");

  const { debounce } = useDebounce();

  const handleSearch = useCallback(
    debounce((searchVal) => {
      if (searchVal) {
        setCoursesData(
          coursesData?.filter((data) => data.name.includes(searchVal))
        );
      } else {
        setCoursesData(enrolledCourses);
      }
    }, 500),
    []
  );

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0 && !enrolledCourses) {
      setLoading(true);
      axios
        .get(getURLs("user-enrolled-courses"), {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          updateUserEnrolledCourses(res.data?.enrolledCourses);
          setCoursesData(res.data?.enrolledCourses);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userInfo, enrolledCourses]);

  return (
    <div
      className={`"dark:bg-darkBgColor1 px-4 space-y-5 ${
        useInDashboard ? "" : "py-5"
      }`}
    >
      <div className="flex items-center space-x-2 justify-between">
        <p className="font-urbanist font-semibold text-sm md:text-base text-blue25 dark:text-white">
          Enrolled Courses
        </p>
        {useInDashboard && enrolledCourses && enrolledCourses.length > 4 && (
          <Link to="/dashboard/enrolled-courses" className="">
            <p className="font-urbanist font-semibold text-blue5 text-sm">
              view all
            </p>
          </Link>
        )}
      </div>
      {/* header */}
      {!useInDashboard && (
        <div className="flex items-center justify-end space-x-2 lg:basis-3/4 w-full">
          <div className="flex dark:bg-purple2 bg-gray9 rounded-md px-2 items-center flex-1">
            <input
              placeholder="Search Courses"
              className="bg-inherit flex-1 w-full py-3 text-sm outline-none border-none placeholder:text-gray30 text-gray30 font-urbanist font-medium"
              onChange={(e) => {
                setSearchInput(e.target.value);
                handleSearch(e.target.value);
              }}
              value={searchInput}
            />

            <MagnifyingGlassIcon className="text-gray30 w-5 h-5" />
          </div>
        </div>
      )}

      {/* courses */}
      <div
        className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ${
          !useInDashboard ? "xl:grid-cols-5" : ""
        } gap-4`}
      >
        {useInDashboard
          ? coursesData
              ?.slice(0, 5)
              ?.map(
                (courseData) =>
                  courseData?.status === "approved" && (
                    <CourseCard
                      key={courseData?._id}
                      data={courseData}
                      courseBookmarked={bookmarkedCourses?.some(
                        (course) =>
                          course.toString() === courseData._id.toString()
                      )}
                    />
                  )
              )
          : coursesData?.map(
              (courseData) =>
                courseData?.status === "approved" && (
                  <CourseCard
                    key={courseData?._id}
                    data={courseData}
                    courseBookmarked={bookmarkedCourses?.some(
                      (course) =>
                        course.toString() === courseData._id.toString()
                    )}
                  />
                )
            )}
      </div>

      {loading && (
        <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
          Loading...
        </p>
      )}

      {/* if there are no respones and not loading */}
      {!loading && coursesData && coursesData?.length === 0 && (
        <h4 className="font-semibold font-urbanist text-center w-full text-lg text-black1 dark:text-white">
          No Enrolled Courses
        </h4>
      )}
    </div>
  );
};

export default ShowEnrolledCourses;
