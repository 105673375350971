import React from "react";

const AvailableBotCard = ({ data, handleCardClick, cardBg, borderBottom }) => {
  return (
    <div
      className={`flex flex-col p-2 ${
        cardBg ? cardBg : "bg-white"
      } shadow-shadow1 rounded-lg mb-6 cursor-pointer hover:scale-105 border`}
      onClick={() => handleCardClick(data.tutorName.toLowerCase())}
    >
      <img
        loading="lazy"
        src={data.tutorImage}
        alt="courseImg"
        className="w-full h-full max-h-24 object-cover rounded-xl mb-4"
      />
      {/* course details text */}
      <p className="font-montserrat font-semibold text-sm lg:text-base text-blue6 mb-1">
        {data.tutorName}
      </p>

      <div
        className={`w-12 border-b-2 ${
          borderBottom ? borderBottom : "border-b-orange-400"
        } mb-3`}
      />
    </div>
  );
};

export default AvailableBotCard;
