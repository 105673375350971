import createDataContext from "../DataContext";
import reducer, { initialState } from "./reducer";
import { updatePromptRunning, updateStopPrompt } from "./actions";

export const {
  Context: PromptRunningContext,
  Provider: PromptRunningProvider,
} = createDataContext(
  reducer,
  { updatePromptRunning, updateStopPrompt },
  initialState
);
