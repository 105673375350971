import React, { useContext } from "react";
import { DarkModeContext } from "../../../../context/darkModeContext";
import { useLocation, useNavigate } from "react-router-dom";

const UserSidebarTab = ({ Icon, title, isImg, IconDark, route }) => {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  const location = useLocation();
  const navigate = useNavigate();

  const handleTabClick = () => {
    navigate(route);
  };

  return (
    <div
      className={`flex items-center space-x-4 cursor-pointer px-4 py-2 md:py-3 rounded-r-lg w-full max-w-xs ${
        location.pathname === route
          ? "bg-gray33 dark:bg-purple4 border-l-2 border-l-blue31"
          : "bg-inherit"
      }`}
      onClick={() => handleTabClick(route)}
    >
      {isImg ? (
        <img
          src={darkMode ? IconDark : Icon}
          alt={title}
          className="h-6 w-6 object-contain"
        />
      ) : (
        <Icon
          className={`h-6 w-6 
         ${
           location.pathname === route
             ? "text-blue31"
             : "text-blue29 dark:text-white"
         }
        `}
        />
      )}

      <p
        className={`font-urbanist text-[12px] sm:text-sm ${
          location.pathname === route
            ? "text-white font-bold"
            : "text-blue29 font-medium dark:text-white"
        }`}
        style={{
          backgroundImage:
            location.pathname === route &&
            "linear-gradient(0deg, #2898FF, #2898FF),linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
          WebkitBackgroundClip: "text",
          color: location.pathname === route && "transparent",
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default UserSidebarTab;
