import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import MarkdownRenderer from "../../MarkdownRender/MarkdownRenderer";

const ShowContentDescription = ({
  isExpandable,
  toggleExpand,
  textStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "unset",
  },
  containerRef,
  content,
  expanded,
}) => {
  return (
    <div className="py-5">
      <h3 className="font-urbanist font-semibold text-base dark:text-white text-black block mb-5">
        Descripiton
      </h3>
      <div className="flex flex-col space-y-3">
        <div
          style={textStyle}
          ref={containerRef}
          className="font-urbanist font-normal dark:text-white text-black text-sm"
        >
          <MarkdownRenderer markdown={content} hideCopyButton={true} />
        </div>

        {isExpandable && (
          <button
            onClick={toggleExpand}
            className="text-blue27 font-urbanist font-semibold text-sm flex items-center space-x-1 cursor-pointer w-max self-center"
          >
            <span>{expanded ? "Show less" : "Show more"}</span>
            {expanded ? (
              <ChevronUpIcon className="text-blue27 h-4 w-4 mt-0.5" />
            ) : (
              <ChevronDownIcon className="text-blue27 h-4 w-4 mt-0.5" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default ShowContentDescription;
