import React from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";

const ConfirmationModal = ({
  confirmationMessage,
  handleModalClose,
  show,
  handleConfirmClick,
  disableButtons,
  error,
}) => {
  return (
    <CustomModal show={show} onHide={handleModalClose} centered>
      {show && (
        <div className="pt-2 pb-4 w-full px-4">
          <div className="flex items-center justify-end w-full pb-2">
            <XCircleIcon
              className="text-gray-400 h-5 w-5 cursor-pointer"
              onClick={handleModalClose}
            />
          </div>

          <div className="flex flex-col items-center space-y-4">
            <h4 className="text-lg md:text-xl font-inter text-black text-center font-medium max-w-sm">
              {confirmationMessage}
            </h4>

            <div className="flex items-center justify-center space-x-2">
              <button
                className="bg-gray-900 disabled:bg-gray-200 text-sm text-white font-urbanist font-medium text-center rounded-md px-6 py-2"
                onClick={handleConfirmClick}
                disabled={disableButtons}
              >
                Confirm
              </button>

              <button
                className="bg-white disabled:bg-gray-50 border border-gray-500 text-sm text-gray-800 font-urbanist font-medium text-center rounded-md px-6 py-2"
                onClick={handleModalClose}
                disabled={disableButtons}
              >
                Cancel
              </button>
            </div>

            {error?.show && (
              <p className="text-red-500 font-urbanist font-medium text-sm text-center">
                {error?.message}
              </p>
            )}
          </div>
        </div>
      )}
    </CustomModal>
  );
};

export default ConfirmationModal;
