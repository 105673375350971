import React, { useContext, useEffect } from "react";
import AvailableBots from "../../AvailableBots";
import { AppDataContext } from "../../../context/appData";
import { useSearchParams } from "react-router-dom";
import Hero from "../../Hero";
import { getBotIds, setBotIds } from "../../../utils/network-requests";
import { UserContext } from "../../../context/user";

const ShowAllAvailableChats = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  // user info context
  const {
    state: { userInfo, userBotInfo },
    updateUserBotInfo,
    updateUserBotIdInfo,
  } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const tutor = searchParams.get("tutor");

  // check for bot's and set
  const checkBotIdsAndSet = () => {
    Promise.all([
      getBotIds("math"),
      getBotIds("science"),
      getBotIds("english"),
      getBotIds("social"),
      getBotIds("french"),
      getBotIds("spanish"),
      getBotIds("counselor"),
    ])
      .then((res) => {
        const botIdsInfo = {
          mathBotId: res[0],
          scienceBotId: res[1],
          englishBotId: res[2],
          socialBotId: res[3],
          frenchBotId: res[4],
          spanishBotId: res[5],
          counselorBotId: res[6],
        };
        setBotIds(botIdsInfo, userInfo?.authToken);
        updateUserBotIdInfo({ ...botIdsInfo });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // check for bot id exist for the user
  useEffect(() => {
    if (Object.keys(userInfo)?.length && !Object.keys(userBotInfo)?.length) {
      const botInfo = sessionStorage.getItem("botInfo");
      if (botInfo) {
        updateUserBotInfo(JSON.parse(botInfo));
      } else {
        checkBotIdsAndSet();
      }
    }
  }, [userInfo, userBotInfo]);

  return (
    <div className="px-4 py-4">
      {tutor ? (
        <Hero useInDashboard tutor={tutor} />
      ) : (
        <AvailableBots pageData={appData} />
      )}
    </div>
  );
};

export default ShowAllAvailableChats;
