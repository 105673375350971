import React, { useContext } from "react";
import CoursesWithTitle from "../CoursesWithTitle";
import { AppDataContext } from "../../context/appData";
import { UserContext } from "../../context/user";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import UserDashboardRightPanel from "./UserDashboardRightPanel/UserDashboardRightPanel";
import ShowEnrolledCourses from "./ShowEnrolledCourses/ShowEnrolledCourses";
import UserOverview from "./UserOverview/UserOverview";

const UserDashboard = () => {
  const {
    state: { allCoursesData },
  } = useContext(AppDataContext);
  const {
    state: { userInfo, enrolledCourses },
  } = useContext(UserContext);

  return (
    <div className="flex items-start space-x-2">
      <div className="flex flex-col bg-white dark:bg-darkBgColor1 flex-1 w-full lg:basis-4/5">
        <div className="flex items-center space-x-2 justify-between pt-5 pb-6 px-5">
          <p className="font-semibold text-lg font-urbanist text-black1 dark:text-white">
            Hello {userInfo?.name} 👋
          </p>
          <div className="flex items-center space-x-2 border border-gray-200 dark:border-purple16 rounded-lg px-2">
            <MagnifyingGlassIcon className="h-6 w-6 text-gray13" />
            <input
              className="outline-none w-full h-full bg-transparent  py-2 flex-1 font-urbanist font-normal text-sm text-gray13"
              placeholder="Search"
            />
          </div>
        </div>

        {/* overview  cards */}
        <div className="mb-5 px-5 flex flex-col space-y-2">
          <UserOverview enrolledCourses={enrolledCourses} />
        </div>

        {/* enrolled courses */}
        {enrolledCourses && enrolledCourses?.length > 0 && (
          <div className="mb-2">
            <ShowEnrolledCourses useInDashboard />
          </div>
        )}

        {/* show courses  */}
        <div className="px-5">
          {!enrolledCourses || enrolledCourses?.length === 0
            ? allCoursesData?.length > 0 && (
                <CoursesWithTitle
                  title="Latest Courses"
                  courses={allCoursesData?.slice(0, 9)}
                  useInDashboard
                  showViewAllButton={allCoursesData?.length > 8}
                />
              )
            : allCoursesData?.length > 0 && (
                <CoursesWithTitle
                  title="Latest Courses"
                  courses={allCoursesData?.slice(0, 5)}
                  useInDashboard
                  showViewAllButton={allCoursesData?.length > 4}
                />
              )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full pb-4"></div>
      </div>
      <div className="hidden w-full h-full lg:block lg:basis-1/5">
        <UserDashboardRightPanel userInfo={userInfo} />
      </div>
    </div>
  );
};

export default UserDashboard;
