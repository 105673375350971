import React, { useContext } from "react";
import CourseCard from "../CourseCard";
import { UserContext } from "../../context/user";
import { Link } from "react-router-dom";

const CoursesWithTitle = ({
  title,
  courses,
  useInDashboard = true,
  showViewAllButton = false,
}) => {
  const {
    state: { bookmarkedCourses },
  } = useContext(UserContext);

  return (
    <div className="space-y-4">
      <h3 className="font-urbanist font-semibold text-sm md:text-base text-blue25 dark:text-white">
        {title}
      </h3>
      {useInDashboard && showViewAllButton && (
        <Link to={"/dashboard/courses"}>
          <p className="font-urbanist font-semibold text-blue5 text-sm">
            view all
          </p>
        </Link>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {courses?.map(
          (courseData) =>
            courseData?.status === "approved" && (
              <CourseCard
                key={courseData?._id}
                data={courseData}
                courseBookmarked={bookmarkedCourses?.some(
                  (course) => course?.toString() === courseData._id.toString()
                )}
              />
            )
        )}
      </div>
    </div>
  );
};

export default CoursesWithTitle;
