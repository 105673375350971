import React from "react";
import OverviewCard from "../OverviewCard/OverviewCard";
import {
  BuildingLibraryIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const UserOverview = ({ enrolledCourses }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-4">
      <h5 className="font-urbanist font-semibold text-sm md:text-base text-blue25 dark:text-white">
        Overview
      </h5>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
        <OverviewCard
          Icon={BuildingLibraryIcon}
          iconBg={"bg-orange-400"}
          cardBg={"bg-orange-400/50"}
          title={"Enrolled Courses"}
          borderBottom={"border-b-orange-400"}
          value={enrolledCourses?.length || 0}
          handleCardClick={() => navigate("/dashboard/enrolled-courses")}
        />

        <OverviewCard
          Icon={CheckCircleIcon}
          iconBg={"bg-green-400"}
          cardBg={"bg-green-400/50"}
          title={"Completed Courses"}
          borderBottom={"border-b-green-400"}
          value={0}
        />

        <OverviewCard
          Icon={DocumentCheckIcon}
          iconBg={"bg-blue-400"}
          cardBg={"bg-blue-400/50"}
          title={"Certificates Earned"}
          borderBottom={"border-b-blue-400"}
          value={0}
        />

        <OverviewCard
          Icon={ClockIcon}
          iconBg={"bg-violet-400"}
          cardBg={"bg-violet-400/50"}
          title={"Time Spend"}
          borderBottom={"border-b-violet-400"}
          value={0}
        />
      </div>
    </div>
  );
};

export default UserOverview;
