import { useState } from "react";
import { format, addDays, subDays } from "date-fns";

const UserCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrev = () => setCurrentDate(subDays(currentDate, 1));
  const handleNext = () => setCurrentDate(addDays(currentDate, 1));

  const getWeekDates = () => {
    const startOfWeek = subDays(currentDate, 3);
    return Array.from({ length: 7 }, (_, i) => addDays(startOfWeek, i));
  };

  return (
    <div className="flex flex-col items-center bg-white p-4 rounded-2xl shadow-lg max-w-full border">
      {/* Month & Year */}
      <div className="flex items-center justify-between w-full">
        <button onClick={handlePrev} className="text-gray-500 text-xl">
          {"<"}
        </button>
        <h2 className="text-sm font-semibold">
          {format(currentDate, "MMMM yyyy")}
        </h2>
        <button onClick={handleNext} className="text-gray-500 text-xl">
          {">"}
        </button>
      </div>

      {/* Date List */}
      <div className="flex gap-2 mt-4">
        {getWeekDates().map((date) => (
          <div
            key={date.toString()}
            className={`flex flex-col items-center justify-center h-16 w-9 rounded-full cursor-pointer transition-all
              ${
                format(date, "yyyy-MM-dd") === format(currentDate, "yyyy-MM-dd")
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-100 text-gray-700"
              }
            `}
            onClick={() => setCurrentDate(date)}
          >
            <span className="text-xs">{format(date, "EEE").toLowerCase()}</span>
            <span className="text-xs font-semibold">{format(date, "d")}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserCalendar;
