import React from "react";
import AvailableBotCard from "./AvailableBotCard/AvailableBotCard";
import { useLocation, useNavigate } from "react-router-dom";

const BotCardBorderBottomColors = [
  "border-b-orange-400",
  "border-b-blue-400",
  "border-b-green-400",
  "border-b-gray-400",
];

const AvailableBots = ({ pageData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCardClick = (botName) => {
    const replace = location.pathname?.includes("tutor");
    navigate(`/dashboard/chat?tutor=${botName}`, replace);
  };

  return (
    <div className="mb-4 rounded-lg py-4">
      <div className="flex flex-col space-y-4">
        <p className="font-inter font-semibold text-base dark:text-white text-blue25">
          {pageData?.dashboardTutorsData?.heading}
        </p>

        <div className="grid grid-cols-3 md:grid-cols-5 xl:grid-cols-6 gap-4">
          {pageData?.dashboardTutorsData?.availableTutors?.map(
            (data, index) => (
              <AvailableBotCard
                handleCardClick={handleCardClick}
                index={index}
                key={index}
                data={data}
                borderBottom={
                  BotCardBorderBottomColors[
                    index % BotCardBorderBottomColors.length
                  ]
                }
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AvailableBots;
