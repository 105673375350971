import { PROMPT_RUNNING, STOP_PROMPT } from "./keys";

export const initialState = {
  promptRunning: false,
  stopPrompt: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case PROMPT_RUNNING: {
      const { promptState } = action.payload;
      return { ...state, promptRunning: promptState };
    }
    case STOP_PROMPT: {
      const { promptState } = action.payload;
      return { ...state, stopPrompt: promptState };
    }
    default:
      return state;
  }
};

export default reducer;
