import React from "react";
import ShowQuestion from "../ShowQuestion/ShowQuestion";
import ShowAnswer from "../ShowAnswer/ShowAnswer";

const ShowPreviousChats = ({ history, animate = false }) => {
  return (
    <div>
      {history?.length > 0 &&
        history?.map((chat, index) => {
          return (
            <div key={index} className="mb-4">
              <ShowQuestion question={chat[0].value} type={chat[0].type} />

              {chat?.[2] && chat[2]?.value?.length > 0 && (
                <div className="flex items-center space-x-2 flex-wrap">
                  {chat[2]?.value?.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt="searched_img"
                      className="h-80 w-80 object-contain"
                    />
                  ))}
                </div>
              )}

              <div className="flex items-start space-x-2">
                {/* logo */}
                <img
                  loading="lazy"
                  src="/logo512.png"
                  alt="logo"
                  className="h-9 object-cover -ml-1 md:ml-0 mt-1"
                />

                <ShowAnswer
                  answer={chat[1].value}
                  type={chat[1].type}
                  animate={animate}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ShowPreviousChats;
